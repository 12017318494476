<template>
  <div class="CalendarShorcuts" border="top">
    <Title :label="$locale['shortcuts']" dpadding />
    <LargeButton :label="$locale['past_month']" icon="calendars" @click="setShorcut(Go.getPastMonthDateRange(new Date(), 'yyyy-mm-dd'))" />
    <LargeButton :label="$locale['last_month']" icon="calendars" @click="setShorcut('last-month')" />
    <LargeButton :label="$locale['last_3_months']" icon="calendars" @click="setShorcut('last-3-months')" />
    <LargeButton :label="$locale['last_6_months']" icon="calendars" @click="setShorcut('last-6-months')" />
    <LargeButton :label="$locale['last_year']" icon="calendars" @click="setShorcut('last-year')" />
    <Spacer num="1" />
  </div>
</template>

<script>
export default {
  props: ["modal", "onShorcut"],
  enum: ["last-month", "last-3-months", "last-6-months", "last-year", "custom"],
  methods: {
    setShorcut: function(shorcut) {
      this.modal.close(() => {
        if (Go.is(this.onShorcut, "Function")) {
          this.onShorcut(shorcut);
        }
      });
    },
  },
};
</script>
